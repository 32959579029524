import React, { useRef, useEffect, useState } from 'react';
import TradingViewChart from '../../components/TradingViewChart';
import WatchList from '../../components/WatchList';
import "./index.css";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AccountManagement from './account/AccountManagement';
import axios from 'axios';
import config from '../../config';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#101013',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '10px',
}));
const Trading = () => {
    const [userID, setUserID] = useState("");
    const [password, setPassword] = useState("");
    const [isAuth, setIsAuth] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: '', severity: '' }); // State for alert
    const [selectedSymbol, setSelectedSymbol] = useState("FX:EURUSD");

    const handleLogin = () => {
        axios.post( `${config.BackendEndpoint}/login`, { userID, password })
            .then((res) => {
                const { state } = res.data;
                console.log(res.data)
                if (state) {
                    const { token } = res.data;
                    localStorage.setItem("tradeToken", token);
                    setIsAuth(true);
                    setAlert({ open: true, message: 'Login Successfully', severity: 'success' });
                    setTimeout(() => setAlert({ ...alert, open: false }), 3000);
                } else {
                    const { msg } = res.data;
                    setAlert({ open: true, message: msg, severity: 'error' });
                    setTimeout(() => setAlert({ ...alert, open: false }), 3000);
                }
            })
            .catch((err) => {
                console.log("Axios Error with ", err);
            })
    }
    return (
        <>
            {alert.open && <Alert severity={alert.severity} onClose={() => setAlert({ ...alert, open: false })} style={{ width: "40%", margin: "auto" }}>{alert.message}</Alert>}
            <div style={{ height: "50px" }}>
                <div style={{ float: 'right', width: '40%' }}>
                    {!isAuth ?
                        <>
                            <TextField label="User ID" variant="standard" onChange={(e) => setUserID(e.target.value)} style={{ margin: '8px 10px 0 0', width: "30%", backgroundColor: "#666666", height: "40px", borderRadius: "5px" }} />
                            <TextField label="Password" variant="standard" type="password" onChange={(e) => setPassword(e.target.value)} style={{ margin: '8px 10px 0 0', width: "30%", backgroundColor: "#666666", height: "40px", borderRadius: "5px" }} />
                            <Button variant="outlined" onClick={handleLogin} style={{ margin: '10px 0 0 0' }}>Login</Button>
                        </>
                        :
                        <>
                            <TextField label="User ID" variant="standard" value={userID} style={{ margin: '8px 10px 0 0', width: "30%", backgroundColor: "#666666", height: "40px", borderRadius: "5px" }} disabled />
                        </>
                    }

                </div>
            </div>
            <div className='trading-page-container'>
                <div style={{ width: '40px' }}></div>
                <div className='tradingview-container'>
                    <div className='chart-container'>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Item sx={{ height: isAuth ? "500px" : "700px" }} p={5}>
                                    <TradingViewChart
                                        selectedSymbol={selectedSymbol}
                                        setSelectedSymbol={setSelectedSymbol}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={4} sx={{ display: 'flex', flexDirection: "column", flex: '' }}>
                                <Item sx={{ height: '80%' }} p={5}>
                                    <WatchList
                                        height={isAuth ? "500" : "700"}
                                    />
                                </Item>
                            </Grid>
                        </Grid>
                    </div>
                    <Box p={1}></Box>
                    {isAuth ?
                        <Box
                            sx={{ borderRadius: '10px', marginBottom: '0px', flex: "33.01 1 0px" }}
                        >
                            <AccountManagement
                                setIsAuth={setIsAuth}
                                setAlert={setAlert}
                                selectedSymbol={selectedSymbol}
                                setSelectedSymbol={setSelectedSymbol}
                            />
                        </Box>
                        : <></>
                    }

                </div>
            </div>
        </>
    )
}

export default Trading;